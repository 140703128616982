import { AppComponent } from 'typescript/typings';
import RoomState from 'pages/room/RoomState';
import { RoomStates } from '@internal/state/room';
import styled from 'styled-components';
import { rem } from 'polished';
import SEO from 'components/SEO';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { captureException } from 'utils/sentry';
import { Typography } from '@internal/ui/legacy/styles';
import { globalBodyPrimary, globalCtaLarge } from '@internal/ui/typography';
import { semanticColor } from '@internal/ui/palette/semantic';
import { Base } from '@internal/ui/palette/base';
import Link from 'next/link';
import { track } from '../../state/app';

interface PageError {
    code: 404 | 500;
    fallbackPath?: string | null;
}

const GIFS = {
    [404]: 'https://giphy.com/embed/b1YtwNsVRgyJ5IbvM3',
    [500]: 'https://giphy.com/embed/WUrgA8xOSUJj6JZoJC',
};

const PageError: AppComponent<PageError> = ({ code, fallbackPath }) => {
    const initialized = useAppSelector((state) => state.user.initialized);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (initialized) {
            captureException(new Error(code === 404 ? 'Page not found' : 'Fatal page error'), {
                statusCode: code,
                appContext: 'error',
            });
        }
    }, [initialized, code]);

    return (
        <PageErrorStyled>
            <SEO title={code === 404 ? 'Page not found' : 'Something went wrong'} />
            <RoomState state={code === 404 ? RoomStates.RoomNotFound : RoomStates.Error} logo>
                {code === 404 ? 'Hmm... we can’t find that page 🤔' : undefined}
            </RoomState>
            <GifStyled
                src={GIFS[code]}
                frameBorder="0"
                className="giphy-embed"
                allowFullScreen={false}
            />
            {code === 404 && fallbackPath && (
                <FallbackText>
                    Did you mean{' '}
                    <Link href={fallbackPath}>
                        <a
                            onClick={() => {
                                dispatch(
                                    track({
                                        event: 'Click 404 Page Fallback Link',
                                        fallback: fallbackPath,
                                    })
                                );
                            }}
                        >{`getplayback.com${fallbackPath}`}</a>
                    </Link>
                    ?
                </FallbackText>
            )}
        </PageErrorStyled>
    );
};

const FallbackText = styled.p`
    ${globalBodyPrimary}
    color: ${Base.Primary200};
    margin-top: ${rem(20)};
    text-align: center;

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        margin-top: ${rem(40)};
    }

    a {
        ${globalCtaLarge}
        color: ${Base.Primary100};

        @media (hover: hover) {
            &:hover {
                text-decoration: underline;
            }
        }
    }
`;

const GifStyled = styled.iframe`
    height: ${rem(280)};
    width: ${rem(280)};
    border-radius: ${rem(12)};
    pointer-events: none;
`;

const PageErrorStyled = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${rem(20)};
    box-sizing: border-box;
`;

export default PageError;
