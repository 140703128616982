import { rem } from 'polished';
import { css } from 'styled-components';
import { Typography } from '../legacy/styles';

export const globalTitle = css`
    ${Typography.FontMedium}
    font-size: ${rem(20)};
    line-height: ${rem(26)};
    letter-spacing: ${rem(0.2)};
`;

export const globalBodyPrimary = css`
    ${Typography.FontNormal}
    font-size: ${rem(16)};
    line-height: ${rem(22)};
    letter-spacing: ${rem(0.1)};
`;

export const globalBodySecondary = css`
    ${Typography.FontNormal}
    font-size: ${rem(13)};
    line-height: ${rem(17)};
    letter-spacing: ${rem(0.1)};
`;

export const globalCtaSmall = css`
    ${Typography.FontMedium};
    font-size: ${rem(13)};
    line-height: ${rem(17)};
    letter-spacing: ${rem(0.2)};
`;

export const globalCtaLarge = css`
    ${Typography.FontMedium};
    font-size: ${rem(16)};
    line-height: ${rem(24)};
    letter-spacing: ${rem(0.2)};
`;

export const globalLabel = css`
    ${Typography.FontBold}
    font-size: ${rem(11)};
    line-height: ${rem(16)};
    text-transform: uppercase;
    letter-spacing: ${rem(1.4)};
`;
